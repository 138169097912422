$fade-ease: cubic-bezier(.82,0,.46,.94);

.logoGradient, .headliner, .beginButton {
  animation: fade-in 1s $fade-ease;
  animation-fill-mode: both;
}

.logoGradient {
  height: 8vh;
}

.headliner {
  animation-delay: 1s;
}

.beginButton {
  height: 15vh;
  animation-delay: 2s;
  cursor: pointer;
  user-select: none;
  .beginButtonC1, .beginButtonC2, .beginButtonC3 {
    animation: c1 25s linear 0s infinite normal both;
  }
  .beginButtonC2 {
    animation-name: c2;
  }
  .beginButtonC3 {
    animation-name: c3;
  }
  fill: #3f3f3f;
  &:hover {
    fill: #666666;
  }
  &:active {
    fill: #272727;
  }
  transition: fill 0.5s;
}

.beginButtonWhite {
  fill: white;
  &:hover {
    fill: #ccc;
  }
  &:active {
    fill: #999;
  }
}

.beStill {
  display: grid;
  animation: slide-in-from-right 1.5s $fade-ease 0.5s;
  animation-fill-mode: both;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.beStillFg, .beStillBg {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
}

.beStillFg {
  animation: blur-in 3s $fade-ease 1.75s;
  animation-fill-mode: both;
}

.curvyBox {
  border-radius: 10px !important;
  padding: 20px;
  text-align: center;
}

.descriptionWrapper {
  display: grid;
  width: 100%; 
  background: linear-gradient(0deg, rgb(38, 67, 142), rgb(26, 122, 191));
  div {
    grid-row: 1;
    grid-column: 1;
    overflow: hidden;
  }
}

.descriptionContainer {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .side {
    flex: 1 0;
  }
  .main {
    width: 80%;
    span {
      color: white;
      font-size: 1.5em;
      display: inline-block;
      img {
        height: 1.5em;
        margin: 0px 0.12em;
        transform: translateY(0.5em);
      }
    }
  }
}

.bokeh {
  position: relative;
  width: 100%;
  height: 100%;
}

.bokeh div {
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) {
      position: absolute;
      border-radius: 50%;
      $size: random(50) + 5px;
      width: $size;
      height: $size;
      top: random(100) * 1%;
      left: random(100) * 1%;
      color: white;
      opacity: 0.1 + random(4) * 0.15;
      box-shadow: ($size + 10px) 0 (0.2px + random() * 0.5px) currentColor;
      filter: blur(0.5px + random(5px));//brightness(50% + random(150) * 1%);
      transform-origin: (random(40) - 20) * 5px (random(40) - 20) * 5px;
      animation-name: bokeh;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 12s + random(30) * 1s;
    }
  }
}


@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(35vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes blur-in {
  0% {
    opacity: 0;
    filter: blur(70px);
    transform: scale(0.3);
  }
  50% {
    opacity: 1;

  }
  100% {
    filter: blur(0px);
    transform: scale(1);
  }
}

@keyframes c1 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(25px, 2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes c2 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-2px, -120px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes c3 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-2px, 60px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes bokeh {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}