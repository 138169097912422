@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: "EB Garamond", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 400;
}

h2 {
  font-weight: 400;
  margin-block-start: 1.3em;
  margin-block-end: 0.6em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
