.goalsListMobile {
  display: none;
}

@media (max-width: 1000px) {
  .goalsListMobile {
    display: block;
  }  
  .goalsList {
    display: none;
  }  
}