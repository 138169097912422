.signinBackground {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: calc(100vh - 90px);
    display: flex;
    margin-top: -70px;
    align-items: center;
    justify-content: center;
}

.signinPaper {
    background-color: #f0f4f8;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    margin-top: 50px;
}

.upperBox {
    margin: -24px 16px 8px;
    padding: 16px;
    text-align: center;
    background: linear-gradient(230deg, rgb(38, 67, 142), rgb(26, 122, 191));
    border-radius: 0.5rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgba(0, 57, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    color: rgb(255 255 255);
}

.mainBox {
    padding: 32px 24px 24px;
    display: flex;
    flex-direction: column;
}

.mainBox > div {
    margin-bottom: 16px;
}