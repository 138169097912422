// .header {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // background-color: rgb(22, 69, 110);
//   background-color: transparent;
//   color: white;
// }

// .menuLink {
//   text-decoration: none;
//   color: '#232363';
// }

.headerBackground {
  //background-image: url("../images/background.jpg");
  //background-repeat: no-repeat;
  //background-position: top;
  //background-size: cover;
  width: 100%;
  min-height: calc(100vh - 90px);
  //display: flex;
  //align-items: center;
  //justify-content: center;
}

.logo {
  margin-right: 10px;
}

.logoMobile {
  display: none;
}

@media (max-width: 900px) {
  .logo {
    display: none;
  }

  .logoMobile {
    display: block;
  }
}